// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".queue-wrapper[data-v-28050061]{background:#133f6b;margin:0;padding:0;width:100%;height:100%}.queue-wrap[data-v-28050061]{margin:0 auto;width:100vw;height:56.25vw;position:relative;background:#133f6b center -1.042vw url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.queue-content[data-v-28050061]{position:absolute;width:62.5vw;height:13.021vw;top:0;right:0;background:#59789b;display:flex}.queue-head[data-v-28050061]{font-family:黑体,Courier New,Courier,monospace;background:#dce3e9;width:3.646vw;height:13.021vw;font-size:1.875vw;padding:0 .26vw;line-height:1.3}.queue-head div[data-v-28050061]{padding-top:2.292vw;color:#133f6b}.queue-body[data-v-28050061]{display:flex;justify-content:space-evenly;width:100%;color:#ecc201}.queue-body .title[data-v-28050061]{padding-top:2.604vw;font-size:1.458vw}.queue-body .title span[data-v-28050061]{display:block}.queue-body .num[data-v-28050061]{font-size:2.917vw;line-height:2}.queue-body .num.small[data-v-28050061]{font-size:1.875vw;line-height:3}", ""]);
// Exports
module.exports = exports;
